import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { SignInPageService } from "../../../../../services";
import { StorageModule } from "@vlinder-web/storage-module-react";

const MODULE_NAME = "switchRoles.machines.states.signInUser";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.SignInPageService") as SignInPageService;
};

export const getAccessToken = async (context: any, event: any) => {
  const currentState = "GET_ACCESS_TOKEN";
  const method = "getAccessToken";
  const storageSrv = StorageModule.getInstance();

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);
      const srv = getSrv();
      const content = await srv.getAccessToken(event?.payload);
      log.blue(MODULE_NAME, '"GET_ACCESS_TOKEN":', content);
      if (content && content?.accessToken) {
        if (event?.payload?.rememberMe) {
          storageSrv.set("userProfile", content, "local");
        } else {
          storageSrv.set("userProfile", content, "local");
        }
        return resolve({
          key: "SIGN_IN_USER_SUCCESS",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = content?.error;
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "SIGN_IN_USER_FAILED",
          value: content,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: "SIGN_IN_USER_FAILED",
        value: "Something went wrong please try again!",
      });
    }
  });
};
