import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { SwitchRolesPageService } from "../../../../../services";

const MODULE_NAME = "switchRoles.machines.states.getAUsersRoles";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    "services.SwitchRolesPageService"
  ) as SwitchRolesPageService;
};

export const getAUsersRoles = async (context: any, event: any) => {
  const currentState = "GET_A_USERS_ROLES";
  const method = "getAUsersRoles";

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);
      console.log("Machine Called Now");

      const srv = getSrv();
      const content = await srv.getAUsersRoles();

      if (content) {
        return resolve({
          key: "GET_A_USERS_ROLES_SUCCESS",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = "Get User Roles failed";
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "GET_A_USERS_ROLES_FAILED",
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: "GET_A_USERS_ROLES_FAILED",
        value: err?.message,
      });
    }
  });
};
