import {
  IAgentPlugin,
  IPluginMethodMap,
} from "@vlinder-web/plugin-agent-service-react";
import config from "../config/inviteUser.config.json";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { CHECK_INVITE_COUNT } from "../graphql";
import { VApplication } from "../../../application";
import notification from "../config/notifications.config.json";
import axios from "axios";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { AppNavigatorService } from "../../appNavigatorService";
const log: any = LoggerModule?.getInstance();

const getGraphQLModule = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};
export interface CheckCountInviteProps {}
export interface ICheckCountInviteMethods extends IPluginMethodMap {
  checkCountInvite(args: CheckCountInviteProps): Promise<any>;
}
export class CheckCountInviteService implements IAgentPlugin {
  readonly methods: ICheckCountInviteMethods;
  constructor() {
    this.methods = {
      checkCountInvite: this.checkCountInvite.bind(this),
    };
  }
  async checkCountInvite() {
    log.blue(`inviteUser server component:`);
    const graphqlModule = getGraphQLModule();
    const client = graphqlModule.getClient("graphql-gateway");
    console.log("in get count invite=========", client);
    try {
      if (client) {
        const res = await client.query({
          query: CHECK_INVITE_COUNT,
          fetchPolicy: "network-only",
        });
        console.log("payload in service check invite-----", res);
        let partitionDefault = 0;
        let partitionSpl = 0;
        let partitionSection = 0;
        if (res?.data?.getMyCommitteInviteStatus) {
          let payload = res?.data?.getMyCommitteInviteStatus;
          const contentArray = payload?.content;
          for (const obj of contentArray) {
            if (
              obj?.partition &&
              (obj?.partition.toUpperCase() === "DEFAULT" ||
                obj?.partition.toUpperCase().includes("DEFAULT"))
            ) {
              if (obj?.maxInvites) {
                partitionDefault += obj?.maxInvites ?? 0;
              }
            }
            if (
              obj?.partition &&
              (obj?.partition.toUpperCase() === "SPECIALRESERVED" ||
                obj?.partition.trim().toUpperCase().includes("SPECIALRESERVED"))
            ) {
              if (obj?.maxInvites) {
                partitionSpl += obj?.maxInvites ?? 0;
              }
            }
            if (
              obj?.partition &&
              (obj?.partition.toUpperCase() === "SECTIONSPECIFIC" ||
                obj?.partition.trim().toUpperCase().includes("SECTIONSPECIFIC"))
            ) {
              if (obj?.maxInvites) {
                partitionSection += obj?.maxInvites ?? 0;
              }
            }
          }
          return {
            response: {
              // partitionDefault: partitionDefault,
              // partitionSpl: partitionSpl,
              // partitionSection: partitionSection,
              contentArray,
            },
          };
        }
        console.log("response is=====", {
          response: {
            partitionDefault: partitionDefault,
            partitionSpl: partitionSpl,
            partitionSection: partitionSection,
          },
        });
        return {
          response: {
            // partitionDefault: partitionDefault,
            // partitionSpl: partitionSpl,
            // partitionSection: partitionSection,
          },
        };
      }
    } catch (err: any) {
      let _display_message =
        err?.graphQLErrors &&
        Array.isArray(err?.graphQLErrors) &&
        err?.graphQLErrors.find((item: any) => item.extensions);
      if (_display_message?.extensions?.display_message) {
        throw Error(_display_message?.extensions?.display_message);
      }

      throw err;
    }
  }
}
