import React from "react";
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  UserRemoveIcon,
  XCircleIcon,
  CreditCardIcon,
} from "@heroicons/react/solid";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";
import "./styles.css";
//import { Media, UncontrolledTooltip } from "reactstrap";
import { ToggleAction } from "./ToggleAction";
// import { AllInbox, Whatshot } from "@material-ui/icons";
// import { capitalCase } from "change-case";
import { Row, Col } from "react-bootstrap";

const capitalizeFirstLetter = (str: string) =>
  str[0].toUpperCase() + str.slice(1);

const getFirstLetterOfEachWord = (text) =>
  text && text?.match(/\b\w/g).join("");

export const DomainTableRow = (props) => {
  const {
    id,
    vuid,
    verified,
    status,
    image,
    name,
    email,
    gender,
    invitedBy,
    band,
    isSelected,
    selectUser,
    deleteUsers,
    onTogglePress,
    role,
    isVIP,
    paymentStatus,
    contentStatus,
    onRowClick,
  } = props;
  const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
  const statusVariant =
    status === "active"
      ? "success"
      : status === "created"
      ? "warning"
      : status === "invited"
      ? "purple"
      : status === "verified"
      ? "secondary"
      : status === "blacklisted"
      ? "danger"
      : "primary";
  const paymentVariant =
    paymentStatus === "PaidComplete"
      ? "success"
      : paymentStatus === "UnPaid"
      ? "danger"
      : paymentStatus === "PaidPartial"
      ? "warning"
      : "dark";
  const boxVariant =
    contentStatus === "Complete"
      ? "success"
      : contentStatus === "Partial"
      ? "warning"
      : "dark";
  const vipVariant = isVIP === true ? "success" : "danger";

  const onToggle = (key: string) => {
    console.log(`toggle in row`, key, email, id);
    onTogglePress && onTogglePress(key, { id: id, vuid: vuid, email: email });
  };

  // const roles = ["Admin","Packer","Actor","Dancer","Dancer","Dancer","Dancer","Packer"];
  const renderCircle = (roles: any) => {
    const colors = ["008000", "800080", "A52A2A", "FF7F50", "d2d2d2", "29A6A6"];

    const output: any = [];

    const returnImg = () => {
      Array.isArray(roles) &&
        roles?.forEach((item, index) => {
          output.push(
            <li className="d-inline-block">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip className="m-0">{item}</Tooltip>}
              >
                <Image
                  style={{ width: "40px" }}
                  className="img-40 rounded-circle"
                  src={`https://ui-avatars.com/api/?name=${item}&background=${colors[index]}&color=fff`}
                />
              </OverlayTrigger>
            </li>
          );
        });
      return output;
    };

    return (
      <div
        className="roleAvatar"
        style={{ width: "100%", inlineSize: "max-content" }}
      >
        <ul style={{ paddingLeft: "0px" }}>{returnImg()}</ul>
      </div>
    );
  };

  const _onRowClick = () => {
    onRowClick && onRowClick(id);
  };

  return (
    <>
      <tr className="border-bottom">
        {/* <td>
          <FormCheck type="checkbox" className="dashboard-check">
            <FormCheck.Input
              id={`user-${id}`}
              checked={isSelected}
              onChange={() => selectUser(id)}
            />
            <FormCheck.Label htmlFor={`user-${id}`} />
          </FormCheck>
        </td> */}
        <td onClick={_onRowClick} className="cursor-pointer">
          <Card.Link className="d-flex align-items-center">
            {image ? (
              <Image src={image} className="avatar rounded-circle me-3" />
            ) : (
              <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-gray-200 me-3">
                <span>{name && getFirstLetterOfEachWord(name.trim())}</span>
              </div>
            )}
            <div className="d-block">
              <span className="fw-bold">{name}</span>
              <div className="small text-gray">{email}</div>
            </div>
          </Card.Link>
        </td>
        <td>
          <ToggleAction status={status} onTogglePress={onToggle} />
          {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="m-0">Delete</Tooltip>}
        >
          <Card.Link
            className="ms-2"
            onClick={() => deleteUsers && deleteUsers(email)}
          >
            <XCircleIcon className="icon icon-xs text-danger" />
          </Card.Link>
        </OverlayTrigger> */}
        </td>

        {/* <td onClick={_onRowClick} className="cursor-pointer">
          <span className="fw-normal d-flex align-items-center">
            <VerifiedIcon
              className={`icon icon-xxs text-${statusVariant} me-1`}
            />
            Email
          </span>
        </td> */}

        <td onClick={_onRowClick} className="cursor-pointer">
          <span className={`fw-normal text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
        </td>
        <td className="mw-20" onClick={_onRowClick}>
          <span className="fw-normal d-flex align-items-center">
            {/* {role} */}
            {renderCircle(role)}
          </span>
        </td>
        <td onClick={_onRowClick} className="cursor-pointer">
          <span className={`fw-normal`}>{invitedBy}</span>
        </td>
        <td onClick={_onRowClick} className="cursor-pointer">
          <span className={`fw-normal`}>{gender}</span>
        </td>
      </tr>
    </>
  );
};
