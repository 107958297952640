import { Form } from "react-bootstrap";
import Creatable from "react-select/creatable";
import styled from "styled-components";

export const Dropdown = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    "& input": {
      color: "black !important",
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "black",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "gray" : "white",
    color: "black",
  }),
};

export const Container = styled(Creatable)`
    width: 100%;
`;

export const FeedbackLabel = styled(Form.Control.Feedback)`
    padding-left: 1rem;
    padding-bottom: 1rem;
`;
export const FeedbackOtherLabel = styled(FeedbackLabel)`
    color: red;
    font-size: 0.875em;
`;